import React from 'react';
import { Link } from 'react-router-dom';
import DarkModeSwitch from './DarkModeSwitch';
import logo from '../assets/logo.png'; // Import your logo

const Header = () => {
  return (
    <header className="bg-blue-600 dark:bg-blue-800 text-white">
      <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <img src={logo} alt="Observant Logo" className="h-8 w-auto mr-2" /> {/* Add your logo here */}
            <span className="font-bold text-xl">Observant</span>
          </Link>
        </div>
        <div className="flex items-center">
          <Link to="/features" className="mx-2">Features</Link>
          <Link to="/pricing" className="mx-2">Pricing</Link>
          <Link to="/about" className="mx-2">About</Link>
          <Link to="/contact" className="mx-2">Contact</Link>
          <Link to="/login" className="mx-2 bg-white text-blue-600 px-4 py-2 rounded">Login</Link>
          <Link to="/register" className="mx-2 bg-green-500 text-white px-4 py-2 rounded">Register</Link>
          <DarkModeSwitch />
        </div>
      </nav>
    </header>
  );
};

export default Header;
