import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const EndpointManagement = () => {
  const [endpoints, setEndpoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEndpoints();
  }, []);

  const fetchEndpoints = async () => {
    setLoading(true);
    try {
      // Replace this with your actual API call
      const response = await fetch('/api/endpoints');
      const data = await response.json();
      setEndpoints(data);
    } catch (err) {
      setError('Failed to fetch endpoints');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteEndpoint = async (id) => {
    try {
      // Replace this with your actual API call
      await fetch(`/api/endpoints/${id}`, { method: 'DELETE' });
      setEndpoints(endpoints.filter(endpoint => endpoint.id !== id));
    } catch (err) {
      setError('Failed to delete endpoint');
      console.error(err);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Manage Endpoints</h1>
      <Link to="/add-endpoint" className="bg-blue-500 text-white px-4 py-2 rounded mb-4 inline-block">
        Add New Endpoint
      </Link>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {endpoints.map(endpoint => (
          <div key={endpoint.id} className="border p-4 rounded">
            <h2 className="text-xl font-semibold">{endpoint.name}</h2>
            <p>Type: {endpoint.type}</p>
            <p>URL: {endpoint.url}</p>
            <div className="mt-4">
              <Link to={`/edit-endpoint/${endpoint.id}`} className="bg-yellow-500 text-white px-3 py-1 rounded mr-2">
                Edit
              </Link>
              <button onClick={() => deleteEndpoint(endpoint.id)} className="bg-red-500 text-white px-3 py-1 rounded">
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EndpointManagement;
