import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmSignUp } from '@aws-amplify/auth';

const ConfirmSignUp = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!email) {
      setError('Email not found. Please go back to the registration page.');
      return;
    }

    try {
      await confirmSignUp(email, code);
      navigate('/login', { state: { message: 'Email confirmed successfully. You can now log in.' } });
    } catch (err) {
      setError(err.message || 'Failed to confirm sign up. Please try again.');
      console.error('Confirmation error:', err);
    }
  };

  return (
    <div className="container mx-auto px-6 py-12 bg-gray-100 dark:bg-gray-900">
      <h1 className="text-4xl font-bold mb-8 text-gray-900 dark:text-white">Confirm Sign Up</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="max-w-md mx-auto">
        <div className="mb-4">
          <label htmlFor="code" className="block mb-2 text-gray-800 dark:text-gray-200">Confirmation Code</label>
          <input
            type="text"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            required
          />
        </div>
        <button type="submit" className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
          Confirm
        </button>
      </form>
    </div>
  );
};

export default ConfirmSignUp;