import React, { useState, useEffect } from 'react';

const AccountManagement = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Here you would typically fetch the user data from your backend
    // For this example, we'll use mock data
    setTimeout(() => {
      setUser({
        email: 'user@example.com',
        name: 'John Doe',
        mfaEnabled: true,
      });
      setLoading(false);
    }, 1000);
  }, []);

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    // Here you would typically send the updated profile data to your backend
    console.log('Profile update submitted');
  };

  const handleToggleMfa = () => {
    // Here you would typically toggle MFA status on your backend
    setUser({ ...user, mfaEnabled: !user.mfaEnabled });
  };

  if (loading) {
    return <div className="container mx-auto px-6 py-12">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold mb-8">Account Management</h1>
      <div className="max-w-md mx-auto">
        <h2 className="text-2xl font-semibold mb-4">Profile Information</h2>
        <form onSubmit={handleUpdateProfile}>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">Email</label>
            <input
              type="email"
              id="email"
              value={user.email}
              className="w-full px-3 py-2 border rounded"
              readOnly
            />
          </div>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">Name</label>
            <input
              type="text"
              id="name"
              value={user.name}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
            Update Profile
          </button>
        </form>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Security Settings</h2>
        <div className="flex items-center justify-between">
          <span>Multi-Factor Authentication</span>
          <button
            onClick={handleToggleMfa}
            className={`py-2 px-4 rounded ${user.mfaEnabled ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'} text-white transition duration-300`}
          >
            {user.mfaEnabled ? 'Enabled' : 'Disabled'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountManagement;
