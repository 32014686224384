import React, { useState } from 'react';

const Features = () => {
  const [modalContent, setModalContent] = useState(null);

  const closeModal = () => setModalContent(null);

  const featureSections = [
    {
      title: "Multi-Protocol Monitoring",
      description: "Observant provides robust monitoring across a wide range of protocols, ensuring comprehensive coverage of your entire infrastructure.",
      items: [
        { 
          name: "HTTP/HTTPS",
          description: "Monitor web services, REST APIs, and website performance",
          details: "Our HTTP/HTTPS monitoring goes beyond simple up/down checks. We analyze response times, verify content, check SSL certificates, and can even simulate complex user interactions to ensure your web services are functioning correctly. NOTE: Supports url parameters and headers."
        },
        { 
          name: "ICMP",
          description: "Check network connectivity and measure latency",
          details: "ICMP monitoring allows you to verify basic network connectivity and measure round-trip times. This is crucial for identifying network issues that could affect your services' performance."
        },
        { 
          name: "SSH",
          description: "Ensure secure shell access to your servers",
          details: "Our SSH monitoring verifies that your servers are accessible via SSH, checks login processes, and can even execute custom commands to validate server health and performance."
        },
        { 
          name: "FTP/FTPS",
          description: "Validate file transfer services",
          details: "We monitor your FTP and SFTP servers to ensure they're operational, responsive, and capable of handling file transfers. This includes checking login processes, file upload/download capabilities, and transfer speeds."
        },
        { 
          name: "SCP",
          description: "Check secure file transfers using SCP",
          details: "SCP monitoring ensures your secure file transfer services are functioning correctly. We verify login processes, file upload/download capabilities, and transfer speeds to guarantee the reliability of your SCP servers."
        },
        {
          name: "Powershell over SSH",
          description: "Execute custom Powershell scripts for advanced monitoring",
          details: "Our Powershell monitoring allows you to run custom scripts on your servers to perform advanced monitoring tasks. This flexibility enables you to monitor virtually any aspect of your infrastructure using Powershell."
        },
        {
          name: "Telnet",
          description: "Verify network connectivity and service availability",
          details: "Telnet monitoring checks network connectivity and service availability on specific ports. This is essential for verifying that your services are accessible and responsive to incoming connections. NOTE: This is not secure and should not be used with anything sensitive."
        },
        {
          name: "PostgreSQL",
          description: "Monitor PostgreSQL database availability and performance",
          details: "Our PostgreSQL monitoring ensures your database servers are available, responsive, and performing optimally. We check connection times, query execution, and database performance metrics to help you maintain reliable data services."
        },
        {
          name: "DNS (Coming Soon)",
          description: "Monitor domain name resolution and DNS server performance",
          details: "DNS monitoring ensures your domain names resolve correctly and your DNS servers are performing optimally. We check domain availability, response times, and DNS record accuracy to help you maintain a reliable online presence."
        },
        { 
          name: "TCP (Coming Soon)",
          description: "Verify port availability and service responsiveness",
          details: "With TCP monitoring, you can ensure specific ports are open and responsive. This is essential for monitoring services like databases, mail servers, and custom applications that rely on specific TCP ports."
        },
        { 
          name: "UDP (Coming Soon)",
          description: "Monitor time-sensitive applications like VoIP or gaming servers",
          details: "UDP monitoring is crucial for real-time applications. We can verify the availability and performance of UDP-based services, helping you maintain high-quality experiences for VoIP, online gaming, and other time-sensitive applications."
        },
        {
          name: "SMTP/POP3/IMAP (Coming Soon)",
          description: "Check email server availability and performance",
          details: "Monitor your email servers to ensure they're available, responsive, and capable of sending and receiving messages. Our SMTP, POP3, and IMAP monitoring helps you maintain reliable email services for your users."
        },
        {
          name: "OWASP API Security (Coming Soon)",
          description: "Validate API security with OWASP Top 20 checks",
          details: "Our OWASP API Security monitoring checks your APIs against the OWASP Top 20 security risks. This helps you identify and address vulnerabilities that could compromise your data and systems."
        },
        {
          name: "Certificate Expiry Monitoring (Coming Soon)",
          description: "Track SSL/TLS certificate expiration dates",
          details: "Our certificate expiry monitoring ensures you're alerted well in advance of SSL/TLS certificate expirations. This helps you avoid service disruptions due to expired certificates and maintain a secure online presence."
        },
        {
          name: "Cipher Suite Monitoring (Coming Soon)",
          description: "Verify SSL/TLS cipher suite configurations",
          details: "Monitor your SSL/TLS cipher suites to ensure they meet security best practices and compliance requirements. Our monitoring helps you maintain secure connections and protect your data from potential vulnerabilities."
        },


      ]
    },
    {
      title: "Advanced Alerting",
      description: "Stay informed about your infrastructure's health with our flexible and powerful alerting system.",
      items: [
        { 
          name: "Multi-channel notifications",
          description: "Receive alerts via email, SMS, voice calls, or integrate with your favorite chat platforms",
          details: "Our alerting system supports a wide range of notification channels. Whether you prefer email, SMS, voice calls, or integration with platforms like Slack or Microsoft Teams, we ensure you're always informed about critical issues."
        },
        { 
          name: "Customizable alert rules",
          description: "Set sophisticated alert conditions based on multiple metrics and thresholds",
          details: "Create complex alert rules that combine multiple conditions and thresholds. This allows you to fine-tune your alerts, reducing noise and ensuring you're notified only when truly necessary."
        },
        { 
          name: "Alert escalation",
          description: "Define escalation policies to ensure critical issues are addressed promptly",
          details: "Set up tiered escalation policies that automatically notify different team members or groups if an alert isn't acknowledged or resolved within specified timeframes."
        },
        { 
          name: "Maintenance windows",
          description: "Suppress alerts during planned maintenance to avoid unnecessary notifications",
          details: "Schedule maintenance windows during which alerts are suppressed or routed differently. This prevents alert fatigue during known maintenance periods and ensures your team isn't bothered by expected issues."
        },
      ]
    },
    {
      title: "In-depth Analytics",
      description: "Gain valuable insights into your infrastructure's performance with our comprehensive analytics tools.",
      items: [
        { 
          name: "Real-time dashboards",
          description: "Visualize your system's health and performance in real-time",
          details: "Our customizable dashboards provide a real-time view of your infrastructure's health and performance. Create tailored views that focus on the metrics most important to your team and business."
        },
        { 
          name: "Historical data analysis",
          description: "Track performance trends and identify recurring issues",
          details: "Dive deep into historical data to uncover trends, identify recurring issues, and make data-driven decisions about your infrastructure. Our analytics tools allow you to compare performance across different time periods and pinpoint the root causes of problems."
        },
        { 
          name: "Custom metrics",
          description: "Define and monitor metrics that matter most to your business",
          details: "In addition to standard system metrics, you can define and track custom metrics specific to your applications and business needs. This allows you to monitor and analyze the KPIs that truly matter to your organization."
        },
        { 
          name: "Uptime reports",
          description: "Generate detailed uptime reports for SLA compliance and performance reviews",
          details: "Automatically generate comprehensive uptime reports for any time period. These reports are perfect for demonstrating SLA compliance, conducting performance reviews, and communicating system reliability to stakeholders."
        },
      ]
    },
    {
      title: "Global Monitoring Network",
      description: "Monitor your services from multiple locations worldwide to ensure consistent performance for all your users.",
      items: [
        { 
          name: "Distributed check locations",
          description: "Verify your services from 30+ global locations",
          details: "Our global network of monitoring nodes allows you to check your services' availability and performance from multiple geographic locations. This ensures you're aware of any regional issues that might affect your users."
        },
        { 
          name: "Regional performance insights",
          description: "Identify and troubleshoot location-specific issues",
          details: "Gain insights into how your services perform in different regions. This allows you to pinpoint and address location-specific performance issues, ensuring a consistent experience for all your users, regardless of their location."
        },
        { 
          name: "Global uptime map",
          description: "Visualize your service availability across different regions",
          details: "Our interactive global uptime map provides an at-a-glance view of your services' availability and performance worldwide. Quickly identify problematic regions and drill down for more detailed information."
        },
      ]
    },
    {
      title: "Integration & Extensibility",
      description: "Seamlessly integrate Observant into your existing workflow and extend its capabilities.",
      items: [
        { 
          name: "RESTful API",
          description: "Automate tasks and integrate Observant data into your applications",
          details: "Our comprehensive RESTful API allows you to programmatically access all aspects of Observant. Automate monitoring tasks, pull data into your own applications, or build custom integrations to suit your specific needs."
        },
        { 
          name: "Webhook support",
          description: "Push real-time updates to your internal systems",
          details: "Use webhooks to receive real-time notifications about monitoring events. This allows you to integrate Observant's alerts and updates directly into your existing systems and workflows."
        },
        { 
          name: "Third-party integrations",
          description: "Connect with popular tools like Slack, PagerDuty, and Jira",
          details: "Observant integrates seamlessly with a wide range of popular tools and services. Whether you need to send alerts to Slack, create incidents in PagerDuty, or generate tickets in Jira, we've got you covered."
        },
        { 
          name: "Custom check types",
          description: "Create specialized checks tailored to your unique requirements",
          details: "In addition to our standard check types, you can create custom checks to monitor specific aspects of your infrastructure or applications. This extensibility ensures that Observant can adapt to your unique monitoring needs."
        },
      ]
    },
  ];

  return (
    <div className="container mx-auto px-6 py-12 bg-white dark:bg-gray-900">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-900 dark:text-white">Observant Features</h1>
      <p className="text-xl text-center mb-12 text-gray-700 dark:text-gray-300">
        Comprehensive monitoring solutions for all your infrastructure needs
      </p>

      <div className="space-y-24">
        {featureSections.map((section, index) => (
          <FeatureSection 
            key={index}
            {...section}
            setModalContent={setModalContent}
          />
        ))}
      </div>

      {modalContent && (
        <Modal content={modalContent} onClose={closeModal} />
      )}
    </div>
  );
};

const FeatureSection = ({ title, description, items, setModalContent }) => {
  return (
    <div className="flex flex-col md:flex-row items-start">
      <div className="md:w-1/3 mb-6 md:mb-0">
        <h2 className="text-2xl font-bold mb-2 text-gray-900 dark:text-white">{title}</h2>
        <p className="text-gray-600 dark:text-gray-400">{description}</p>
      </div>
      <div className="md:w-2/3 md:pl-8 grid grid-cols-1 sm:grid-cols-2 gap-6">
        {items.map((item, index) => (
          <div 
            key={index} 
            className="feature-item bg-gray-100 dark:bg-gray-800 p-4 rounded-lg transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg cursor-pointer"
            onClick={() => setModalContent(item)}
            style={{
              perspective: '1000px',
            }}
          >
            <div className="feature-item-inner transition-transform duration-300 ease-in-out" style={{ transformStyle: 'preserve-3d' }}>
              <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">{item.name}</h3>
              <p className="text-gray-700 dark:text-gray-300">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Modal = ({ content, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-2xl w-full">
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">{content.name}</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">{content.details}</p>
        <button 
          onClick={onClose}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Features;