import React from 'react';

const About = () => {
  return (
    <div className="bg-white dark:bg-gray-900">
      {/* Hero Section */}
      <section className="relative bg-blue-600 text-white py-20">
        <div className="container mx-auto px-6">
          <h1 className="text-5xl font-bold mb-4">Revolutionizing Infrastructure Monitoring</h1>
          <p className="text-xl mb-8">Empowering businesses with real-time insights since 2023</p>
        </div>
        <div className="absolute bottom-0 left-0 right-0">
          <svg viewBox="0 0 1440 120" fill="currentColor" className="text-white dark:text-gray-900">
            <path d="M0,32L60,53.3C120,75,240,117,360,117.3C480,117,600,75,720,64C840,53,960,75,1080,80C1200,85,1320,75,1380,69.3L1440,64L1440,120L1380,120C1320,120,1200,120,1080,120C960,120,840,120,720,120C600,120,480,120,360,120C240,120,120,120,60,120L0,120Z"></path>
          </svg>
        </div>
      </section>

      {/* Mission Section */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 dark:text-white">Our Mission</h2>
          <p className="text-xl text-center mb-12 text-gray-600 dark:text-gray-300">
            At Observant, we're on a mission to simplify infrastructure monitoring, enabling businesses of all sizes to maintain peak performance and reliability. We believe that robust monitoring should be accessible, intuitive, and actionable.
          </p>
          <div className="flex flex-wrap -mx-4">
            <MissionCard 
              icon={<svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" /></svg>}
              title="Reliability"
              description="We're committed to providing a monitoring service you can always count on."
            />
            <MissionCard 
              icon={<svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" /></svg>}
              title="Performance"
              description="We continuously optimize our platform to deliver lightning-fast insights."
            />
            <MissionCard 
              icon={<svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" /></svg>}
              title="Innovation"
              description="We're always exploring new ways to make monitoring more efficient and insightful."
            />
          </div>
        </div>
      </section>

      {/* Story Section */}
      <section className="bg-gray-100 dark:bg-gray-800 py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 dark:text-white">Our Story</h2>
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-1/2 mb-12 md:mb-0">
              <img src="/path-to-your-image.jpg" alt="Observant team" className="rounded-lg shadow-xl" />
            </div>
            <div className="w-full md:w-1/2 md:pl-12">
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Founded in 2023 by a team of passionate engineers, Observant was born out of frustration with existing monitoring solutions. We saw a need for a more intuitive, powerful, and flexible monitoring platform that could keep pace with modern infrastructure.
              </p>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                What started as a side project quickly grew into a full-fledged service, as more and more businesses recognized the value of our approach to infrastructure monitoring. Today, we're proud to serve thousands of customers worldwide, from startups to Fortune 500 companies.
              </p>
              <p className="text-gray-600 dark:text-gray-300">
                As we continue to grow, our commitment remains the same: to provide the most effective, user-friendly monitoring solution on the market, empowering businesses to maintain peak performance and reliability.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 dark:text-white">Meet Our Team</h2>
          <div className="flex flex-wrap -mx-4">
            <TeamMember 
              name="Ron McCorkle"
              role="CEO & founder"
              image="/ron.jpg"
            />
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to revolutionize your monitoring?</h2>
          <p className="text-xl mb-8">Join thousands of businesses that trust Observant for their critical infrastructure monitoring.</p>
          <a href="/signup" className="bg-white text-blue-600 py-2 px-6 rounded-full text-lg font-semibold hover:bg-blue-100 transition duration-300">Get Started Today</a>
        </div>
      </section>
    </div>
  );
};

const MissionCard = ({ icon, title, description }) => (
  <div className="w-full md:w-1/3 px-4 mb-8">
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 text-center">
      <div className="text-blue-500 mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-white">{title}</h3>
      <p className="text-gray-600 dark:text-gray-300">{description}</p>
    </div>
  </div>
);

const TeamMember = ({ name, role, image }) => (
  <div className="w-full md:w-1/4 px-4 mb-8">
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
      <img src={image} alt={name} className="w-full h-64 object-cover" />
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-1 text-gray-800 dark:text-white">{name}</h3>
        <p className="text-gray-600 dark:text-gray-300">{role}</p>
      </div>
    </div>
  </div>
);

export default About;