import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const MfaSetup = () => {
  const [qrCode, setQrCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Here you would typically fetch the QR code from your backend
    // For this example, we'll use a placeholder image
    setQrCode('https://via.placeholder.com/200x200.png?text=MFA+QR+Code');
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Here you would typically send the verification code to your backend
      // const response = await api.verifyMfa(verificationCode);
      console.log('MFA verification submitted:', verificationCode);
      
      // Simulate successful verification
      navigate('/dashboard');
    } catch (err) {
      setError('MFA verification failed. Please try again.');
    }
  };

  return (
    <div className="container mx-auto px-6 py-12 bg-white dark:bg-gray-900 text-black dark:text-white">
      <h1 className="text-4xl font-bold mb-8">Set Up MFA</h1>
      <div className="max-w-md mx-auto">
        <p className="mb-4 dark:text-gray-300">Scan this QR code with your authenticator app:</p>
        <img src={qrCode} alt="MFA QR Code" className="mb-6" />
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="verificationCode" className="block mb-2 dark:text-gray-300">Verification Code</label>
            <input
              type="text"
              id="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className="w-full px-3 py-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>
          <button type="submit" className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
            Verify MFA
          </button>
        </form>
      </div>
    </div>
  );
};

export default MfaSetup;
