import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, confirmSignIn } from '@aws-amplify/auth';
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    mfaCode: '',
  });
  const [error, setError] = useState('');
  const [showMfaPrompt, setShowMfaPrompt] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const navigate = useNavigate();
  const recaptchaRef = useRef();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setError('Please complete the reCAPTCHA');
      return;
    }

    try {
      const user = await signIn({
        username: formData.username,
        password: formData.password,
      });
      setCognitoUser(user);
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setShowMfaPrompt(true);
      } else {
        navigate('/dashboard');
      }
    } catch (err) {
      setError('Failed to login. Please check your credentials.');
      console.error('Login error:', err);
    }
  };

  const handleMfaSubmit = async (e) => {
    e.preventDefault();
    try {
      await confirmSignIn(cognitoUser, formData.mfaCode, cognitoUser.challengeName);
      navigate('/dashboard');
    } catch (err) {
      setError('Failed to verify MFA. Please try again.');
      console.error('MFA error:', err);
    }
  };

  return (
    <div className="container mx-auto px-6 py-12 bg-gray-100 dark:bg-gray-900">
      <h1 className="text-4xl font-bold mb-8 text-gray-900 dark:text-white">Login</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {!showMfaPrompt ? (
        <form onSubmit={handleSubmit} className="max-w-md mx-auto">
          <div className="mb-4">
            <label htmlFor="username" className="block mb-2 text-gray-800 dark:text-gray-200">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block mb-2 text-gray-800 dark:text-gray-200">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
              required
            />
          </div>
          <div className="mb-6">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="AIzaSyB9CH-ai36fr9TXBUFV03PfInAMp1_OGXU"
            />
          </div>
          <button type="submit" className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
            Log In
          </button>
        </form>
      ) : (
        <form onSubmit={handleMfaSubmit} className="max-w-md mx-auto">
          <div className="mb-6">
            <label htmlFor="mfaCode" className="block mb-2 text-gray-800 dark:text-gray-200">MFA Code</label>
            <input
              type="text"
              id="mfaCode"
              name="mfaCode"
              value={formData.mfaCode}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
              required
            />
          </div>
          <button type="submit" className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
            Verify MFA
          </button>
        </form>
      )}
    </div>
  );
};

export default Login;