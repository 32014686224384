const awsconfig = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HW0Rydv9U",
    "aws_user_pools_web_client_id": "37c7lih7ikq2g5dt3oibhhv7j0",
    "oauth": {
        "domain": "https://identity.observant.zerosandones.us",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://observant.zerosandones.us/",
        "redirectSignOut": "https://observant.zerosandones.us/signout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};

export default awsconfig;