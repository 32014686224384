import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeContext, ThemeProvider } from './context/ThemeContext';
import { getCurrentUser } from '@aws-amplify/auth';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Register from './pages/Register';
import ConfirmSignUp from './pages/ConfirmSignUp';
import MfaSetup from './pages/MfaSetup';
import AccountManagement from './pages/AccountManagement';
import EndpointManagement from './pages/EndpointManagement';
import AddEndpoint from './pages/AddEndpoint';
import Dashboard from './pages/Dashboard';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

// Protected route component
const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCurrentUser()
      .then(() => setIsAuthenticated(true))
      .catch(() => setIsAuthenticated(false))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

function AppContent() {
  const { darkMode } = useContext(ThemeContext);

  return (
    <Router>
      <div className={`flex flex-col min-h-screen ${darkMode ? 'dark' : ''}`}>
        <Header />
        <main className="flex-grow bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white">
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/features" element={<Features />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/confirm-signup" element={<ConfirmSignUp />} />
            <Route path="/mfa-setup" element={<MfaSetup />} />
            <Route path="/account" element={<AccountManagement />} />
            {/* Remove authentication for these routes */}
            <Route path="/manage-endpoints" element={<EndpointManagement />} />
            <Route path="/add-endpoint" element={<AddEndpoint />} />
            {/* Keep Dashboard protected */}
            <Route 
              path="/dashboard" 
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } 
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

export default App;