import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const [modalContent, setModalContent] = useState(null);

  const closeModal = () => setModalContent(null);

  const regions = [
    { id: 1, name: 'North America', locations: ['New York', 'San Francisco', 'Toronto'], coming: false },
    { id: 2, name: 'South America', locations: [], coming: true },
    { id: 3, name: 'Europe', locations: [], coming: true },
    { id: 4, name: 'Asia', locations: [], coming: true },
    { id: 5, name: 'Australia', locations: [], coming: true },
    { id: 6, name: 'Africa', locations: [], coming: true },
  ];

  const jobTypeComparison = [
    { jobType: 'HTTP/HTTPS', observant: '✓', competitor1: '✓', competitor2: '✓', details: 'Full support including custom headers, SSL verification, and content matching' },
    { jobType: 'ICMP (Ping)', observant: '✓', competitor1: '✓', competitor2: '✓', details: 'Measure latency and packet loss' },
    { jobType: 'TCP', observant: '✓', competitor1: 'Limited', competitor2: '✓', details: 'Check specific port availability and response time' },
    { jobType: 'UDP', observant: '✓', competitor1: '✗', competitor2: 'Limited', details: 'Monitor time-sensitive applications like VoIP' },
    { jobType: 'DNS', observant: '✓', competitor1: '✓', competitor2: '✓', details: 'Verify DNS resolution and check for specific records' },
    { jobType: 'SSH', observant: '✓', competitor1: '✗', competitor2: 'Limited', details: 'Ensure SSH access and execute custom commands' },
    { jobType: 'FTP/SFTP', observant: '✓', competitor1: 'Limited', competitor2: '✓', details: 'Check file transfer capabilities and response times' },
    { jobType: 'SMTP/IMAP/POP3', observant: '✓', competitor1: '✓', competitor2: 'Limited', details: 'Comprehensive email server monitoring' },
    { jobType: 'Database (MySQL, PostgreSQL)', observant: '✓', competitor1: '✗', competitor2: 'Limited', details: 'Direct database health checks and query execution' },
    { jobType: 'Custom Scripts', observant: '✓', competitor1: '✗', competitor2: '✗', details: 'Run custom scripts for specialized monitoring needs' },
  ];

  return (
    <div className="bg-gray-100 dark:bg-gray-900">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-20">
        <div className="container mx-auto px-6 text-center">
          <h1 className="text-5xl font-bold mb-4">Welcome to Observant</h1>
          <p className="text-xl mb-8">Comprehensive infrastructure monitoring for the modern web</p>
          <Link to="/register" className="bg-white text-blue-600 py-2 px-6 rounded-full text-lg font-semibold hover:bg-blue-100 transition duration-300">
            Get Started
          </Link>
        </div>
      </section>

      {/* Feature Highlights Section */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12 dark:text-white">Why Choose Observant?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon={<svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" /></svg>}
              title="Real-time Monitoring"
              description="Get instant alerts and up-to-the-minute status updates on your infrastructure."
            />
            <FeatureCard
              icon={<svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>}
              title="Multi-Protocol Support"
              description="Monitor HTTP, ICMP, TCP, UDP, SSH, and more from a single platform."
            />
            <FeatureCard
              icon={<svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" /></svg>}
              title="Customizable Checks"
              description="Create and customize monitoring checks to fit your specific needs."
            />
          </div>
        </div>
      </section>

      {/* Job Type Comparison Table */}
      <section className="py-20 bg-white dark:bg-gray-800">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-900 dark:text-white">Job Type Comparison</h2>
          <div className="overflow-x-auto">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="bg-gray-200 dark:bg-gray-700">
                  <th className="py-4 px-6 font-bold uppercase text-sm text-gray-600 dark:text-gray-300 border-b border-gray-300 dark:border-gray-600">Job Type</th>
                  <th className="py-4 px-6 font-bold uppercase text-sm text-gray-600 dark:text-gray-300 border-b border-gray-300 dark:border-gray-600">Observant</th>
                  <th className="py-4 px-6 font-bold uppercase text-sm text-gray-600 dark:text-gray-300 border-b border-gray-300 dark:border-gray-600">Competitor 1</th>
                  <th className="py-4 px-6 font-bold uppercase text-sm text-gray-600 dark:text-gray-300 border-b border-gray-300 dark:border-gray-600">Competitor 2</th>
                  <th className="py-4 px-6 font-bold uppercase text-sm text-gray-600 dark:text-gray-300 border-b border-gray-300 dark:border-gray-600">Details</th>
                </tr>
              </thead>
              <tbody>
                {jobTypeComparison.map((row, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-gray-100 dark:bg-gray-900' : 'bg-white dark:bg-gray-800'}>
                    <td className="py-4 px-6 border-b border-gray-300 dark:border-gray-600">{row.jobType}</td>
                    <td className="py-4 px-6 border-b border-gray-300 dark:border-gray-600">{row.observant}</td>
                    <td className="py-4 px-6 border-b border-gray-300 dark:border-gray-600">{row.competitor1}</td>
                    <td className="py-4 px-6 border-b border-gray-300 dark:border-gray-600">{row.competitor2}</td>
                    <td className="py-4 px-6 border-b border-gray-300 dark:border-gray-600">{row.details}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      {/* Global Coverage Section */}
      <section className="bg-white dark:bg-gray-800 py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12 dark:text-white">Global Coverage</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
            {regions.map((region) => (
              <RegionCard 
                key={region.id}
                region={region}
                onClick={() => setModalContent(region)}
              />
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to get started?</h2>
          <p className="text-xl mb-8">Join thousands of companies that trust Observant for their monitoring needs.</p>
          <Link to="/register" className="bg-white text-blue-600 py-2 px-6 rounded-full text-lg font-semibold hover:bg-blue-100 transition duration-300">
            Sign Up Now
          </Link>
        </div>
      </section>

      {modalContent && (
        <Modal content={modalContent} onClose={closeModal} />
      )}
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 text-center transform transition duration-500 hover:scale-105">
    <div className="text-blue-500 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2 dark:text-white">{title}</h3>
    <p className="text-gray-600 dark:text-gray-300">{description}</p>
  </div>
);

const RegionCard = ({ region, onClick }) => (
  <div 
    className={`bg-gray-100 dark:bg-gray-700 rounded-lg p-6 text-center cursor-pointer transform transition duration-500 hover:scale-105 ${region.coming ? 'opacity-75' : ''}`}
    onClick={onClick}
  >
    <h3 className="text-xl font-semibold mb-2 dark:text-white">{region.name}</h3>
    <p className="text-gray-600 dark:text-gray-300">
      {region.coming ? 'Coming Soon' : `${region.locations.length} Locations`}
    </p>
  </div>
);

const Modal = ({ content, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full">
      <h2 className="text-2xl font-bold mb-4 dark:text-white">{content.name}</h2>
      {content.coming ? (
        <p className="text-gray-600 dark:text-gray-300 mb-4">We're expanding to this region soon! Stay tuned for updates.</p>
      ) : (
        <>
          <p className="text-gray-600 dark:text-gray-300 mb-2">Monitoring locations in this region:</p>
          <ul className="list-disc list-inside mb-4">
            {content.locations.map((location, index) => (
              <li key={index} className="text-gray-600 dark:text-gray-300">{location}</li>
            ))}
          </ul>
        </>
      )}
      <button 
        onClick={onClose}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
      >
        Close
      </button>
    </div>
  </div>
);

export default Home;